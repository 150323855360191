<template>
    <div id="service-container">
        <router-view></router-view>
    </div>
</template>

<script>
  export default {
    name: 'index',
    created(){
      document.title = "配送员";
      let path = this.$route.query.path;
      let query = "";
      if (this.$route.query.query != "undefined") {
        query = this.$route.query.query;
      }
      if (!this.$utils.rule.empty(query)) {
        query = JSON.parse(decodeURIComponent(query));
      }
      if (path) {
        this.$router.replace({ path: "services/" + path, query: query });
      }
    }
  }
</script>

<style scoped>
    #service-container {
        background: #fff;
        min-height: 100vh;
    }
</style>